import React from "react";
import PropTypes from "prop-types";
import { Bar as HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { FormatAsDollars } from "../../../../utils/Helpers/Format";
import { useIntl } from 'react-intl';
import "../../../../utils/chartSetup";

const CostToOwnComparisonChart = ({
  carNames,
  carsCosts,
  displayEvCostBreakdownOption,
  displayEvCostBreakdown
}) => {
  let chartData = {};

  const intl = useIntl()

  chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.vehicle", defaultMessage: "Vehicle net Incentives, Resale"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_NET}`,
        data: carsCosts.map(carCost => {
          return (
            carCost.vehicle.total
          )
        })
      },
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.electricity", defaultMessage: "Electricity"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
        data: carsCosts.map(carCost => {
          return (
            carCost.electricity.total
          )
        })
      },
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.gasoline", defaultMessage: "Gasoline"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
        data: carsCosts.map(carCost => {
          return (
            carCost.gasoline.total
          )
        })
      },
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.maintenance", defaultMessage: "Maintenance"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_MAINTENANCE}`,
        data: carsCosts.map(carCost => {
          return (
            carCost.maintenance.total
          )
        })
      },
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.insurance", defaultMessage: "Insurance"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_INSURANCE}`,
        data: carsCosts.map(carCost => {
          return (
            carCost.insurance.total
          )
        })
      }
    ]
  };
  
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          value = FormatAsDollars(value, intl.locale);
          return " " + label + ": " + value;
        },
        title: function(tooltipItems, data) {
          let total = tooltipItems.reduce(function(acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ": " + FormatAsDollars(total, intl.locale);
        }
      }
    },
    scales: {
      x: {
          stacked: true,
          ticks: {
            callback: function(value) {
              value = FormatAsDollars(value, intl.locale);
              return value;
            },
            beginAtZero: true
          }
        },
      y:  {
          stacked: true,
          ticks: {
            font: {
              weight: 700
            }
          }
        }
    },
    legend: {
      position: "bottom",
      onClick: function(e) {
        e.stopPropagation();
      },
      display: true
    },
    layout: {
      padding: {
        right: 75
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach(dataset => {
              sum += dataset.data[ctx.dataIndex];
            });
            return FormatAsDollars(sum, intl.locale);
          } else {
            return "";
          }
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700
        }
      }
    }
  };

  return (
    <HorizontalBar
      data={chartData}
      height={212}
      type="horizontalBar"
      options={options}
    />
  );
};

export default CostToOwnComparisonChart;

CostToOwnComparisonChart.propTypes = {
  carNames: PropTypes.array,
  carCosts: PropTypes.array,
  displayEvCostBreakdownOption: PropTypes.bool,
  displayEvCostBreakdown: PropTypes.bool,
  displayFuelOnly: PropTypes.bool
};