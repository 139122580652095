import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import InputWorkingZipcodeForChargingMap from "../../client_customizations/components/InputComponents/InputZipcode/InputZipcodeForChargingMap";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import "./LocationMap.scss";
import { FormattedMessage, useIntl } from "react-intl";
import useChargingStations from "../../hooks/useChargingStations";
import filterWithinBounds from "../../utils/Helpers/filterWithinBounds";
import BaseGoogleMapReact from "./BaseGoogleMapReact/BaseGoogleMapReact";
import useMappedClusters from "../../hooks/useMappedClusters";
import ChargingStationsMapMarker from "./ChargingStationsMapMarker/ChargingStationsMapMarker";
import ChargingStationClusterMarker from "./ChargingStationClusterMarker/ChargingStationClusterMarker";
import isSuperChargingStation from "../../utils/predicates/isSuperChargingStation";
import useSelectedStation from "../../hooks/useSelectedStation";
import MapControlPanel from "../../client_customizations/components/MapControlPanel/MapControlPanel";
import ChargingStationsList from "./ChargingStationsList/ChargingStationsList";

const ChargingMap = ({ userLocation, ignoreSuperchargerStations, isVisible = true }) => {
     const [bounds, setBounds] = useState({
          nw: undefined,
          ne: undefined,
          sw: undefined,
          se: undefined,
     });
     const [center, setCenter] = useState();
     const [zoom, setZoom] = useState(13);
     const mapRef = useRef();
     const intl = useIntl();

     useEffect(() => {
          if (!userLocation) return;
          setCenter({
               lat: parseFloat(userLocation.latitude),
               lng: parseFloat(userLocation.longitude),
          });
     }, [userLocation]);

     const { chargingStations, fetchChargingStations } = useChargingStations();
     const [selectedStation, selectStation, deselectStations] = useSelectedStation(chargingStations);
     const visibleChargingStations = filterWithinBounds(chargingStations, bounds);
     const filteredChargingStations = ignoreSuperchargerStations
          ? visibleChargingStations.filter((station) => !isSuperChargingStation(station))
          : visibleChargingStations;

     const { clusteredPoints, individualPoints, supercluster } = useMappedClusters({
          data: filteredChargingStations,
          bounds,
          zoom,
     });

     const onMapChange = ({ center: newCenter, bounds: newBounds, zoom: newZoom }) => {
          setBounds(newBounds);
          setZoom(newZoom);
          const { lat: latitude, lng: longitude } = newCenter;
          fetchChargingStations({ latitude, longitude }, newBounds);
     };

     const saveMapRefs = ({ map }) => {
          mapRef.current = map;
     };

     return (
          <div className="ChargingMap">
               <div className="row hide-offscreen">
                    <div className="col-sm-12 text-center">
                         <h2>
                              <FormattedMessage
                                   id="chargingStations"
                                   defaultMessage="Charging Stations"
                                   description="Charging Stations"
                              />
                         </h2>
                    </div>
               </div>
               <div className="row">
                    <div className="col-sm-3">
                         <MapControlPanel selectedStation={selectedStation} chargingStations={filteredChargingStations}>
                              <InputWorkingZipcodeForChargingMap />
                         </MapControlPanel>
                    </div>
                    <div className="col-sm-9">
                         {!userLocation && (
                              <div className="spinner-container">
                                   <LoadingSpinner />
                              </div>
                         )}
                         {userLocation && (
                              <div className="map-container" aria-hidden="true">
                                   {isVisible && (
                                        <BaseGoogleMapReact
                                             center={center}
                                             zoom={zoom}
                                             onChange={onMapChange}
                                             onGoogleApiLoaded={saveMapRefs}
                                        >
                                             {individualPoints.map((point) => (
                                                  <ChargingStationsMapMarker
                                                       key={point.properties.data.id}
                                                       station={point.properties.data}
                                                       lat={point.geometry.coordinates[1]}
                                                       lng={point.geometry.coordinates[0]}
                                                       onMouseOver={() => {
                                                            selectStation(point.properties.data.id);
                                                       }}
                                                       onClick={deselectStations}
                                                  />
                                             ))}
                                             {clusteredPoints.map((cluster) => (
                                                  <ChargingStationClusterMarker
                                                       key={cluster.id}
                                                       cluster={cluster}
                                                       supercluster={supercluster}
                                                       map={mapRef.current}
                                                       lat={cluster.geometry.coordinates[1]}
                                                       lng={cluster.geometry.coordinates[0]}
                                                  />
                                             ))}
                                        </BaseGoogleMapReact>
                                   )}
                              </div>
                         )}
                         <p className="legal-disclaimer" style={{ margin: "8px 0" }}>
                              <FormattedMessage
                                   id="chargingMap.chargerMapDisclaimer"
                                   defaultMessage="Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {link}."
                                   values={{
                                        link: (
                                             <a
                                                  href="https://afdc.energy.gov/stations#/station/new"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                             >
                                                  {intl.formatMessage({ id: "here", defaultMessage: "here" })}
                                             </a>
                                        ),
                                   }}
                              />
                         </p>
                    </div>
               </div>
               <div className="row">
                    <div className="col-sm-12">
                         <ChargingStationsList chargingStations={filteredChargingStations} />
                    </div>
               </div>
          </div>
     );
};

export default ChargingMap;

ChargingMap.propTypes = {
     userLocation: PropTypes.object,
     ignoreSuperchargerStations: PropTypes.bool,
};
